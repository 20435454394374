<template>
  <div id="myproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">My Product</p>
          </div>
        </div>
        <hr class="m-0 p-0" />

     
      </div>
    </div>
   
  </div>
</template>

<script>
 
export default {
  name: "MyProduct",
 
  data() {
    return {
       
    };
  },
  watch: {
   
  },
  computed: {},
  mounted() {
   
  },
  methods: {
    
  },
};
</script>

<style>
</style>